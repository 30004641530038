import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { RouterProvider } from "react-router-dom";
import { router } from "./App";
import { deDE } from '@mui/material/locale';
import { deDE as deDEGrid } from '@mui/x-data-grid-pro/locales';
import { ConfirmProvider } from "material-ui-confirm";
import firebaseConfig from "./config/firebaseConfig";
import { LicenseInfo } from '@mui/x-license';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'


LicenseInfo.setLicenseKey('cb281eda1913cb3eb88d567b21f8534eTz05NzUxMixFPTE3NTcwNjg2NzcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg==');


const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
var theme;
if (firebaseConfig.projectId == "litport-heine-c2471") {
    console.log("Firebase Config:", firebaseConfig)

    theme = createTheme({
        palette: {
            primary: { main: "#C60000" },
            text: {

            },
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        color: "#ffffff",
                        '& .MuiButton-root': {
                            color: "#ffffff",
                        },
                        '& .MuiIconButton-root': {
                            color: "#ffffff",
                        },

                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        // color: "#ffffff",
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        // color: "#ffffff",
                    },
                },
            },
        },
        typography: {
            subtitle1: {
                fontSize: 12,
            },
            body1: {

            },
            button: {
                fontSize: 14,

            },
        },
    },
        deDE,
        deDEGrid
    );
} else {
    console.log("Firebase Config not found")

    theme = createTheme({
        palette: {
            primary: { main: "#A5C500" }
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        color: "#ffffff",
                        '& .MuiButton-root': {
                            color: "#000000",
                        },
                        '& .MuiIconButton-root': {
                            color: "#000000",
                        },

                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        color: "#000000",
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        color: "#000000",
                    },
                },
            },
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        color: "#000000",
                    },
                },
            },

        },

        typography: {
            subtitle1: {
                fontSize: 12,
            },
            body1: {

            },
            button: {
                fontSize: 14,

            },
        },
    },
        deDE,
    )
}


// console.log("Process Env:", process.env)

root.render(
    // <StrictMode>
    <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ConfirmProvider>
            <RouterProvider router={router} />
        </ConfirmProvider>
        </LocalizationProvider>
    </ThemeProvider>
    // </StrictMode>
);


// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
