import React, { Component, useState, useEffect } from 'react'
import { useAuth, apiFetch } from "../hooks/useAuth";
// import {
//     DataGrid, GridRowsProp, GridColDef,
//     GridToolbarContainer, GridToolbarExport, GridActionsCellItem
// } from '@mui/x-data-grid';

import { DataGridPro, GridToolbar, GridToolbarContainer, GridToolbarExport, GridActionsCellItem } from '@mui/x-data-grid-pro';

import EmailIcon from '@mui/icons-material/Email';
import SquareIcon from '@mui/icons-material/Square';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import {
    IconButton, FormControlLabel, Stack, Alert,
    LinearProgress, Box, Modal, Typography, Tooltip
} from "@mui/material/";
import dayjs from 'dayjs';
import { ModalDownload, ModalContact } from "./Modal";
import { deDE } from '@mui/x-data-grid-pro/locales'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    height: 600,
    p: 4,
};

export function AboListUmlauf() {
    const { user, change, claims, accountID } = useAuth();
}

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport
                printOptions={{
                    hideFooter: true,
                    hideToolbar: true,
                }}
            />
        </GridToolbarContainer>
    );
}

export function AboList() {
    const { user, change, claims, accountID } = useAuth();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('');
    const [message, setMessage] = useState("");
    const [data, setData] = useState([]);
    const [dataUmlauf, setDataUmlauf] = useState([]);
    const [open, setOpen] = useState(false);
    const [openUmlauf, setOpenUmlauf] = useState(false);
    const [openLieferhistorie, setOpenLieferhistorie] = useState(false);
    const token = useAuth().token; // funktioniert nur so

    const [currentRow, setCurrentRow] = useState(null);

    const [isModalDownloadOpen, setIsModalDownloadOpen] = useState(false);
    const [isModalContactOpen, setIsModalContactOpen] = useState(false);

    const handleCloseUmlauf = () => {
        setError("");
        setOpenUmlauf(false);
    }

    const handleOpenUmlauf = (row) => {
        setOpenUmlauf(true);

        if (row.AuftragNr !== null) {
            var orderID = row.AuftragNr;
        }

        apiFetch("/app/abos/umlauf/" + accountID + "/" + orderID)
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Die Daten konnten nicht geladen werden. ');
                }
                return res.json();
            })
            .then((data) => {
                setLoading(false);
                console.log(data);
                setDataUmlauf(data);

                if (data.message)
                    setMessage(data.message);
            })
            .catch((err) => {
                setLoading(false);
                setError(err.message);
            });

    }

    const handleCloseLieferhistorie = () => {
        setError("");
        setOpenLieferhistorie(false);
    }

    const handleOpenLieferhistorie = (row) => {
        setOpenLieferhistorie(true);

        if (row.AuftragNr !== null) {
            var orderID = row.AuftragNr;
        }

        apiFetch("/app/abos/lieferungen/" + accountID + "/" + orderID)
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Die Daten konnten nicht geladen werden. ');
                }
                return res.json();
            })
            .then((data) => {
                setLoading(false);
                console.log(data);
                setDataUmlauf(data);

                if (data.message)
                    setMessage(data.message);
            })
            .catch((err) => {
                setLoading(false);
                setError(err.message);
            });

    }



    const currencyFormatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
    });

    useEffect(() => {
        setData("");

        if (!accountID) {
            setError("Es wurde keine AccountID ausgewaehlt");
            return;
        }

        if (!token) {
            setError("Es gibt keinen definierten User Token");
            return;
        }
        setLoading(true);

        apiFetch("/app/abos/" + accountID)
            .then((res) => res.json())
            .then((data) => {
                setLoading(false);
                console.log(data);
                setData(data)
                if (data.message)
                    setMessage(data.message);
            })
            .catch((err) => {
                setLoading(false);
                setError(err.message);
            });
    }, [accountID]);

    return (

        <div style={{ height: "670px", width: '100%' }}>
            <Box
                mt={2}
                mb={2}
                sx={{ width: '100%', height: 4 }}>
                {loading && <LinearProgress />}

            </Box>

            {error && <Alert severity="error">{error}</Alert>}
            {message && <Alert severity="info">{message}</Alert>}


            <Modal
                open={openUmlauf}
                onClose={handleCloseUmlauf}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <DataGridPro
                        pageSize={10}
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                        components={{
                            Toolbar: CustomToolbar,
                        }}

                        initialState={{ pinnedColumns: { right: ['Reklamation'] } }}
                        rows={dataUmlauf}
                        columns={[
                            {
                                field: 'AuftragNr',
                                headerName: "AuftragsNr.",
                            },
                            {
                                field: 'Adressat',
                                headerName: "Adressat",
                                width: "180"
                            },
                            {
                                field: 'AuftragPosLfdnr',
                                headerName: "AuftragPosLfdnr"
                            },
                            {
                                field: 'Kostenstelle',
                                headerName: "Kostenstelle"
                            },
                            {
                                field: 'Pos',
                                headerName: "Pos"
                            },
                            {
                                field: 'Titel',
                                headerName: "Titel",
                                width: "230",
                            },

                        ]}
                    />
                </Box>
            </Modal>

            <Modal
                open={openLieferhistorie}
                onClose={handleCloseLieferhistorie}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <DataGridPro
                        pageSize={10}
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                        components={{
                            Toolbar: CustomToolbar,
                        }}



                        initialState={{ pinnedColumns: { right: ['Reklamation'] } }}
                        rows={dataUmlauf}
                        columns={[
                            {
                                field: 'AuftragNr',
                                headerName: "AuftragsNr.",
                            },
                            {
                                field: 'Titel',
                                headerName: "Titel",
                                width: "250",
                            },
                            {
                                field: 'AuftragPosLfdnr',
                                headerName: "AuftragPosLfdnr"
                            },
                            {
                                field: 'Lieferung',
                                headerName: "Lieferung",
                                width: "250",
                            },
                            {
                                field: 'Datum',
                                headerName: "Datum",
                                type: "date",
                                valueFormatter: (params) => dayjs(params).format('DD.MM.YYYY'),

                            },


                        ]}
                    />
                </Box>
            </Modal>

            <ModalDownload row={currentRow} isModalOpen={isModalDownloadOpen} handleCloseModal={() => setIsModalDownloadOpen(false)} />
            <ModalContact row={currentRow} isModalOpen={isModalContactOpen} handleCloseModal={() => setIsModalContactOpen(false)} />

            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>

                    <DataGridPro
                        pageSize={10}
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                        components={{
                            Toolbar: GridToolbar
                        }}
                        // // getRowId={(row) => row.AuftragNr}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                            },
                            filterPanel: {
                                onKeyDown: (event) => {
                                    console.log(event.key);
                                    if (event.key === 'Enter') {
                                        apiRef.current.hideFilterPanel()
                                    }
                                }
                            }
                        }}

                        initialState={{ pinnedColumns: { right: ['Reklamation'] } }}
                        rows={data}
                        columns={[

                            {
                                field: 'AuftragNr',
                                headerName: "AuftragsNr.",
                                width: 90
                            },
                            {
                                field: 'KDNR',
                                headerName: "KundenNr.",
                                width: 85
                            },
                            {
                                field: 'Titel',
                                width: 360
                            },
                            {
                                field: 'Anzahl',
                                width: 60
                            },
                            {
                                field: 'Gesamtbetrag',
                                // valueFormatter: (params) => {
                                //     return params.value.toFixed(2) + " €";
                                // },
                                valueFormatter: (params) => {
                                    return currencyFormatter.format(params);
                                },
                                headerName: "Betrag",
                                type: "number",
                                width: 80,
                            },
                            {
                                field: 'Bestelldatum1',
                                headerName: "bestellt am",
                                type: "date",
                                valueFormatter: (params) => dayjs(params).format('DD.MM.YYYY'),
                            },
                            {
                                field: 'Hinweis',
                                width: 180
                            },
                            {
                                field: 'Kostenstelle'
                            },
                            /*{
                                field: 'ISBN'
                            },*/
                            // {
                            //     field: 'ErscheintAm',
                            //     headerName: "erscheint am",
                            //     width: 150
                            // },
                            {
                                field: 'geliefertAm',
                                headerName: "letzte Lieferung",
                                width: 120
                            },
                            {
                                field: 'actions',
                                type: 'actions',
                                headerName: 'Umlauf',
                                width: 80,
                                getActions: (params) => {
                                    if (params.row.Umlauf !== 0) {
                                        return (
                                            [
                                                <GridActionsCellItem
                                                    onClick={() => {
                                                        handleOpenUmlauf(params.row);
                                                    }}
                                                    icon={<AddCircleOutlineIcon />}
                                                    label="Umlauf" />
                                            ]
                                        )
                                    } else {
                                        return (
                                            [
                                                <></>
                                            ]
                                        )
                                    }

                                },
                            },
                            {
                                field: 'actions2',
                                type: 'actions',
                                headerName: 'Aktionen',
                                width: 120,
                                getActions: (params) => [
                                    <Tooltip title="Reklamation">
                                        <GridActionsCellItem
                                            onClick={() => {
                                                // handleOpen(params.row);
                                                setCurrentRow(params.row);
                                                setIsModalContactOpen(true);

                                            }}
                                            icon={<EmailIcon />} label="Reklamation" />

                                    </Tooltip>,

                                    <Tooltip title="Belege herunterladen">
                                        <GridActionsCellItem
                                            onClick={() => {
                                                // handleOpenDownload(params.row);
                                                setCurrentRow(params.row);
                                                setIsModalDownloadOpen(true);
                                            }}
                                            icon={<CloudDownloadIcon />} label="Download" />

                                    </Tooltip>,

                                    <Tooltip title="Lieferhistorie anzeigen">
                                        <GridActionsCellItem
                                            onClick={() => {
                                                // handleOpenDownload(params.row);
                                                setCurrentRow(params.row);
                                                handleOpenLieferhistorie(params.row);
                                            }}
                                            icon={<DeliveryDiningIcon />} label="Lieferung" />

                                    </Tooltip>,
                                ],
                            },
                        ]}
                    />
                </div>
            </div>

        </div >
    )
}

