import { useEffect, useState } from "react";
import { Grid, Paper, Select, MenuItem, Typography, Box, FormControl, InputLabel, Card, CardContent, LinearProgress } from "@mui/material";


import { LineChart } from '@mui/x-charts';
import { AccountSelect, AccountSelectCheck } from "./AccountSelect";

import { useAuth, apiFetch } from "../hooks/useAuth";
import dayjs from 'dayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { deDE } from '@mui/x-date-pickers/locales';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Countdown } from "./Countdown";
import 'dayjs/locale/de';

export const WelcomeData = ({ }) => {
    const { user, change, claims, accountID } = useAuth();
    const [groupedData, setGroupedData] = useState([]);
    const [kostenstelleIds, setKostenstelleIds] = useState([]);
    const [selectedKostenstelle, setSelectedKostenstelle] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dataType, setDataType] = useState("all");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [data, setData] = useState(null);
    const [totalsAuftragsart, setTotalsAuftragsart] = useState(null);
    const [totalSum, setTotalSum] = useState(null);
    const [activeAbos, setActiveAbos] = useState(0);
    const [sumOrdered, setSumOrdered] = useState(0);
    const [sumBooked, setSumBooked] = useState(0);

    const currencyFormatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
    });


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                setError(null);
                setSelectedKostenstelle("")

                let data = [];
                let data_temp = [];
                // if (dataType === "orders") {

                const ordersResponse = await apiFetch("/app/orders/" + accountID);
                const abosResponse = await apiFetch("/app/abos/" + accountID);
                const ordersData = await ordersResponse.json();
                const abosData = await abosResponse.json();
                data_temp = [...ordersData, ...abosData];

                if (dataType == "all") {
                    data = data_temp;
                } else {
                    data = data_temp.filter(item => { return item.ArtikelTyp === dataType });
                }


                // Step 1: Filter the data by the date range
                const filteredByDate = data.filter(item => isDateWithinRange(item.Bestelldatum1));
                setData(filteredByDate);

                // Step 2: Group the filtered data by Kostenstelle and sum the Gesamtbetrag
                const grouped = filteredByDate.reduce((acc, item) => {
                    const { Kostenstelle, Gesamtbetrag } = item;
                    if (!acc[Kostenstelle]) {
                        acc[Kostenstelle] = { Kostenstelle, Gesamtbetrag: 0, items: [] };
                    }
                    acc[Kostenstelle].Gesamtbetrag += parseFloat(Gesamtbetrag);
                    acc[Kostenstelle].items.push(item);
                    return acc;
                }, {});


                const groupedArray = Object.values(grouped);
                setGroupedData(groupedArray);


                const uniqueKostenstellen = [...new Set(data.map(item => item.Kostenstelle))];
                setKostenstelleIds(uniqueKostenstellen);

                const groupedData = filteredByDate.reduce((acc, curr) => {
                    if (!acc[curr.ArtikelTyp]) {
                        acc[curr.ArtikelTyp] = { sum: 0, ArtikelTyp: curr.ArtikelTyp };
                    }
                    acc[curr.ArtikelTyp].sum += curr.Gesamtbetrag;
                    return acc;
                }, {});
                setTotalsAuftragsart(groupedData);


                const totalSum = filteredByDate.reduce((sum, curr) => sum + curr.Gesamtbetrag, 0);
                setTotalSum(totalSum);


                const activeAbos = filteredByDate.reduce((acc, curr) => {
                    if (curr.AboAktiv === "aktiv") {
                        acc++
                    }
                    return acc;
                }, 0);
                setActiveAbos(activeAbos);

                const sumOrdered = filteredByDate.reduce((acc, curr) => {
                    if (curr.Bestellstatus === "bestellt" && curr.ArtikelTyp === "Titel") {
                        return acc + curr.Gesamtbetrag;
                    }
                    return acc;
                }, 0);
                setSumOrdered(sumOrdered);

                const sumBooked = filteredByDate.reduce((acc, curr) => {
                    if (curr.Bestellstatus === "berechnet" && curr.ArtikelTyp === "Titel") {
                        return acc + curr.Gesamtbetrag;
                    }
                    return acc;
                }, 0);
                setSumBooked(sumBooked);

            } catch (err) {
                setError("Error fetching data: " + err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [accountID, dataType, startDate, endDate]);

    const handleKostenstelleChange = (event) => {
        setSelectedKostenstelle(event.target.value);

        const groupedData = data.reduce((acc, curr) => {
            if (!acc[curr.Auftragsart]) {
                if (event.target.value === curr.Kostenstelle) {
                    acc[curr.ArtikelTyp] = { sum: 0, Auftragsart: curr.ArtikelTyp };
                }
            }
            console.log(curr);
            if (event.target.value === curr.Kostenstelle) {
                acc[curr.ArtikelTyp].sum += parseFloat(curr.Gesamtbetrag);
            }

            return acc;
        }, {});
        console.log(groupedData);
        setTotalsAuftragsart(groupedData);

        const totalSum = data.reduce((sum, curr) => {
            if (event.target.value === curr.Kostenstelle) {
                return sum + parseFloat(curr.Gesamtbetrag); // Return the updated sum
            }
            return sum; // Return the current sum if condition is not met
        }, 0);
        console.log(totalSum);
        setTotalSum(totalSum);


        const activeAbos = data.reduce((acc, curr) => {

            if (curr.AboAktiv === "aktiv" && event.target.value === curr.Kostenstelle) {
                acc++
            }
            return acc;
        }, 0);
        setActiveAbos(activeAbos);

        const sumOrdered = data.reduce((acc, curr) => {
            if (curr.AboAktiv === "bestellt" && event.target.value === curr.Kostenstelle && curr.ArtikelTyp === "Titel") {
                return acc + curr.Gesamtbetrag;
            }
            return acc;
        }, 0);
        setSumOrdered(sumOrdered);

        const sumBooked = data.reduce((acc, curr) => {
            if (curr.Bestellstatus === "berechnet" && event.target.value === curr.Kostenstelle && curr.ArtikelTyp === "Titel") {
                return acc + curr.Gesamtbetrag;
            }
            return acc;
        }, 0);
        setSumBooked(sumBooked);


    };

    const handleDataTypeChange = (event) => {
        setDataType(event.target.value);
    };

    const isDateWithinRange = (dateString) => {
        const dateToCompare = dayjs(dateString);
        if (!startDate && !endDate) return true;

        if (startDate && dateToCompare.isBefore(startDate)) {
            return false;
        }

        if (endDate && dateToCompare.isAfter(endDate)) {
            return false;
        }

        return true;
    };

    const filteredData = groupedData.filter(group => {
        const matchesKostenstelle = selectedKostenstelle ? group.Kostenstelle === selectedKostenstelle : true;
        return matchesKostenstelle;
    });

    const chartData = Object.fromEntries(
        Object.entries(
            filteredData.reduce((acc, group) => {
                group.items.forEach(item => {
                    const month = new Date(item.Bestelldatum1).toLocaleString('default', { month: 'short', year: 'numeric' });
                    if (!acc[month]) {
                        acc[month] = 0;
                    }
                    acc[month] += parseFloat(item.Gesamtbetrag);
                });
                return acc;
            }, {})
        ).reverse()
    );

    const months = Object.keys(chartData);
    const totalAmount = Object.values(chartData);


    return (

        <Grid container spacing={3}>

            {false &&
                <Grid item xs={12} md={3}>
                    <Paper elevation={3} style={{ padding: '20px' }}>
                        <Typography style={{ 'margin-bottom': '20px' }} variant="body1">Filter nach:</Typography>

                        <AccountSelectCheck
                            sx={{
                                mb: 10,
                                minWidth: 130,
                            }}
                        />

                        <FormControl fullWidth style={{ marginTop: '20px' }}>
                            <InputLabel id="data-type-select-label">Artikeltyp</InputLabel>
                            <Select
                                labelId="data-type-select-label"
                                id="data-type-select"
                                value={dataType}
                                onChange={handleDataTypeChange}
                                label="Data Type"
                            >
                                <MenuItem value="all">Alle</MenuItem>
                                <MenuItem value="Titel">Titel</MenuItem>
                                <MenuItem value="Fortsetzung">Fortsetzung</MenuItem>
                                <MenuItem value="Zeitschrift">Zeitschrift</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth style={{ marginTop: '20px' }}>
                            <InputLabel id="kostenstelle-select-label">Kostenstelle</InputLabel>
                            <Select
                                labelId="kostenstelle-select-label"
                                id="kostenstelle-select"
                                value={selectedKostenstelle}  // Bind to state
                                onChange={handleKostenstelleChange}
                                label="Kostenstelle"
                                renderValue={(value) => {
                                    if (value === "") return "Alle"; // Show "Alle" for filter with empty string
                                    if (value === "NO_KOSTENSTELLE") return "Keine Kostenstelle"; // Custom value for missing Kostenstelle
                                    return value; // Show the actual Kostenstelle
                                }}
                            >
                                <MenuItem value="">Alle</MenuItem> {/* "Alle" option */}
                                <MenuItem value="NO_KOSTENSTELLE">Keine Kostenstelle</MenuItem> {/* Custom option for rows without Kostenstelle */}

                                {kostenstelleIds.filter(ks => ks !== "").map((kostenstelle, index) => (
                                    <MenuItem key={index} value={kostenstelle}>
                                        {kostenstelle}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Box mt={4}>
                            <Typography style={{ 'margin-bottom': '20px' }} variant="body1">Nach Datum filtern:</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                <DatePicker
                                    label="Startdatum"
                                    value={startDate}
                                    onChange={(newValue) => setStartDate(newValue)}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                <DatePicker
                                    label="Enddatum"
                                    value={endDate}
                                    onChange={(newValue) => setEndDate(newValue)}
                                />
                            </LocalizationProvider>
                        </Box>
                    </Paper>
                </Grid>
            }
            {false &&
                <Grid item xs={12} md={9}>
                    {loading &&
                        <Box
                            mt={2}
                            mb={2}
                            sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>}
                    {loading ? (
                        <Countdown></Countdown>
                    ) : error ? (
                        <Typography variant="body1" color="error">{error}</Typography>
                    ) : filteredData.length > 0 ? (
                        <>
                            <Box >

                                <Paper elevation={3} style={{ padding: '20px', height: "400px", width: "100%" }}>
                                    <Typography variant="h6" align="center">Gesamtbetrag pro Monat</Typography>
                                    <LineChart
                                        xAxis={[{ scaleType: 'point', data: months, label: 'Monat' }]}
                                        series={[
                                            { data: totalAmount, label: 'Gesamtbetrag (€)' }
                                        ]}

                                        height={300}
                                    />
                                </Paper>
                            </Box>

                            <Grid container spacing={2} style={{ 'margin-top': '20px' }}>
                                {/* Total Card */}
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card key={1}>
                                        <CardContent>
                                            <Typography variant="h5">Gesamtbetrag</Typography>
                                            <Typography variant="h6">
                                                {currencyFormatter.format(totalSum.toFixed(2))}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                {/* Cards for each Auftragsart */}
                                {Object.values(totalsAuftragsart).map((group) => (
                                    <Grid item xs={12} sm={6} md={4} key={group.ArtikelTyp}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="h5">
                                                    Gesamtbetrag {group.ArtikelTyp === "Titel" ? "Titel" : group.ArtikelTyp}

                                                </Typography>
                                                <Typography variant="h6">
                                                    {currencyFormatter.format(group.sum.toFixed(2))}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}

                                <Grid item xs={12} sm={6} md={4}>
                                    <Card key={1}>
                                        <CardContent>
                                            <Typography variant="h5">Gesamtbetrag Titel berechnet</Typography>
                                            <Typography variant="h6">

                                                {currencyFormatter.format(sumBooked.toFixed(2))}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <Card key={1}>
                                        <CardContent>
                                            <Typography variant="h5">Gesamtbetrag Titel bestellt</Typography>
                                            <Typography variant="h6">

                                                {currencyFormatter.format(sumOrdered.toFixed(2))}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>


                                <Grid item xs={12} sm={6} md={4}>
                                    <Card key={1}>
                                        <CardContent>
                                            <Typography variant="h5">Aktive Abos</Typography>
                                            <Typography variant="h6">
                                                {activeAbos}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            {filteredData.map((group, index) => (
                                <Box key={index} mt={4}>
                                    <Paper elevation={3} style={{ padding: '20px' }}>
                                        <Typography variant="h6">Kostenstelle: {group.Kostenstelle}</Typography>
                                        <Typography variant="body1">Gesamtbetrag: {currencyFormatter.format(group.Gesamtbetrag.toFixed(2))}</Typography>

                                        <Box mt={2}>
                                            <Typography variant="body2">Details:</Typography>
                                            {group.items.map((item, idx) => (
                                                <Box key={idx} mb={1}>
                                                    <Typography variant="body2">AuftragNr: {item.AuftragNr}, Titel: {item.Titel}</Typography>
                                                    <Typography variant="body2">Einzelpreis: {currencyFormatter.format(item.Einzelpreis)}, Anzahl: {item.Anzahl}</Typography>
                                                    <Typography variant="body2">Bestelldatum: {new Date(item.Bestelldatum1).toLocaleDateString()}</Typography>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Paper>
                                </Box>
                            ))}


                        </>
                    ) : (
                        <Typography variant="body1">No data available</Typography>
                    )}
                </Grid>}
        </Grid>
    );
};
