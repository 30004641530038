import { BasicPage } from "../components/BasicPage";
import { useAuth, apiFetch } from "../hooks/useAuth";
import BarChart from "@mui/icons-material/BarChart";
import { useEffect, useState } from "react";

import {
    Card, Typography, CardContent, Box, Alert, LinearProgress, Paper, Grid, Select, MenuItem, FormControl, InputLabel
} from "@mui/material/";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LineChart } from '@mui/x-charts';
import { WelcomeData } from "../components/WelcomeData";

export const WelcomePage = () => {
    const { user, change, claims, accountID } = useAuth();
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [data, setData] = useState('');
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [kostenstelle, setKostenstelle] = useState('');

    const token = useAuth().token;

    const numberFormat = (value) =>
        new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR'
        }).format(value);

    useEffect(() => {
        setError("");
        setMessage("");

        if (!accountID) {
            setError("Es wurde keine AccountID ausgewaehlt");
            return;
        }

        if (!token) {
            setError("Es gibt keinen definierten User Token");
            return;
        }

        setLoading(true);

        // API call including the selected start and end dates
        const startDateParam = startDate ? startDate.toISOString().split('T')[0] : '';
        const endDateParam = endDate ? endDate.toISOString().split('T')[0] : '';
        const url = `/app/stats/${accountID}?startDate=${startDateParam}&endDate=${endDateParam}`;

        apiFetch(url)
            .then(response => response.json())
            .then(data => {
                setLoading(false);
                setData(data[0]);
                if (data.message) setMessage(data.message);
            })
            .catch((err) => {
                setError(err.message);
                setLoading(false);
            });
    }, [accountID, startDate, endDate]);


    return (
        <BasicPage title="Willkommen im Litport v2" icon={<BarChart />}>
            <Grid container spacing={2} direction="row" alignItems="flex-start" mt={6}>
                {/* Sidebar for Filters */}
                <WelcomeData data={accountID} />
           


                {/* Commented out sections */}
                {/* <Grid> */}
                {/*    <Typography variant="h6" mt={6} mb={2}>Empfehlungen</Typography> */}
                {/* </Grid> */}

                {/* <SearchNewbooks></SearchNewbooks> */}
            </Grid>
        </BasicPage>
    );
};
