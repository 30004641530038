import React, { Component, useState, useEffect } from 'react'
import { useAuth, apiFetch } from "../hooks/useAuth";
import {
    DataGrid, GridToolbar, GridRowsProp, GridColDef,
    GridToolbarContainer, GridToolbarExport, GridActionsCellItem
} from '@mui/x-data-grid';

import EmailIcon from '@mui/icons-material/Email';
import SquareIcon from '@mui/icons-material/Square';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import {
    IconButton, FormControlLabel, Stack, Alert,
    LinearProgress, Box, Modal, Typography, Tooltip
} from "@mui/material/";

import { FormContact } from '../components/FormFields';
import dayjs from 'dayjs';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    height: 600,
    p: 4,
};

export function ModalContact(props) {

    return (
        <Modal
            open={props.isModalOpen}
            onClose={props.handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Reklamation
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Reklamation für Auftrag {props.row?.AuftragNr}
                </Typography>
                <FormContact currentRow={props.row} handleClose={props.handleClose} template="orderIssue" />
            </Box>
        </Modal>
    );
}

export function ModalDownload(props) {
    const { accountID, token } = useAuth();
    const [openDownload, setOpenDownload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [dataDocuments, setdataDocuments] = useState([]);
    const [currentRow, setCurrentRow] = useState(null);
    const [file, setFile] = useState(null);


    const handleDownload = async (row) => {

        if (!accountID) {
            setError("Es wurde keine AccountID ausgewählt");
            return;
        }

        if (!token) {
            setError("Es gibt keinen definierten User Token");
        }

        const response = await apiFetch("/app/download/" + accountID + "/" + row.PDF_Name, {
            headers: {
                "Authorization": "Bearer " + token,
            },
        });

        if (!response.headers.get('content-disposition')) {
            setError("Download nicht möglich. Es können nur aktuelle Belege heruntergeladen werden.");
            return;
        }
        const filename = response.headers.get('content-disposition').split('filename=')[1].replaceAll('"', '');

        const blob = await response.blob();
        // response.headers.forEach(console.log);

        console.log(filename);

        const url = URL.createObjectURL(blob, { type: 'application/pdf' });
        var a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);

        setFile({ filename, url });

        //window.location.assign(url);
        //window.open(url, '_blank');
    }

    useEffect(() => {
        if (props.isModalOpen) {
            fetchData();
        }
    }, [props.isModalOpen]);


    function fetchData() {
        var row = props.row;
        if (row.AuftragPosLfdnr !== null) {
            var orderID = row.AuftragPosLfdnr;
        }

        apiFetch("/app/documents/" + accountID + "/" + orderID)
            .then((res) => res.json())
            .then((data) => {
                setLoading(false);
                console.log(data);
                setdataDocuments(data);

                if (data.message)
                    setMessage(data.message);
            })
            .catch((err) => {
                setLoading(false);
                setError(err.message);
            });
    }

    const handleOpenDownload = (row) => {
        setOpenDownload(true);
        setCurrentRow(row);
        console.log(row);

        if (row.AuftragPosLfdnr !== null) {
            var orderID = row.AuftragPosLfdnr;
        }

        apiFetch("/app/documents/" + accountID + "/" + orderID)
            .then((res) => res.json())
            .then((data) => {
                setLoading(false);
                console.log(data);
                setdataDocuments(data);

                if (data.message)
                    setMessage(data.message);
            })
            .catch((err) => {
                setLoading(false);
                setError(err.message);
            });
    }

    const handleCloseDownload = () => {
        setError("");
        setOpenDownload(false);
    }

    return (
        <Modal
            open={props.isModalOpen}
            onClose={props.handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">Beleg Download</Typography>
                {error && <Alert severity="error">{error}</Alert>}
                <DataGrid
                    pageSize={100}
                   
                    // components={{
                    //     // Toolbar: CustomToolbar,
                    // }}

                    rows={dataDocuments}
                    columns={[
                        {
                            field: 'AuftragsPosLfdNr',
                            headerName: "AuftragsPosLfdNr"
                        },
                        {
                            field: 'Belegdatum',
                            headerName: "Belegdatum"
                        },
                        {
                            field: 'PDF_Name',
                            headerName: "PDF_Name",
                            width: 140,
                        },
                        {
                            field: 'actions',
                            type: 'actions',
                            headerName: 'Aktionen',
                            width: 100,
                            getActions: (params) => [
                                <Tooltip title="Belege herunterladen">
                                    <GridActionsCellItem
                                        onClick={() => {
                                            handleDownload(params.row);
                                            // console.log(params.row);
                                            // console.log(params.row.id);
                                        }}
                                        icon={<CloudDownloadIcon />} label="Download" />
                                </Tooltip>

                            ],
                        },

                    ]}
                />
            </Box>
        </Modal>
    )

}