const firebaseConfig = {
    apiKey: "AIzaSyCzbG1Fab-nc-XoQePHZoUSCwYsBWJhLJM",
    authDomain: "litport-heine-c2471.firebaseapp.com",
    projectId: "litport-heine-c2471",
    storageBucket: "litport-heine-c2471.appspot.com",
    messagingSenderId: "460708659415",
    appId: "1:460708659415:web:da206c1c40d82adf5a7092",
    imgSrc: "/img/logo_heine.svg",
    imgWidth: "400px",
    imprintLink: "https://heinefachmedien.de/impressum",
    email: ["rkeser@collectivum.io", "John.harms@brunswiker.de", "service@heinefachmedien.de"]
};
export default firebaseConfig;

