import * as React from 'react';
import { Component, useState, useEffect } from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useAuth, apiFetch } from "../hooks/useAuth";

import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import ListItemText from '@mui/material/ListItemText';

export function AccountSelect(props) {

    let sx = props.sx;

    const { user, claims, accountID, changeAccountID } = useAuth();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [data, setData] = useState(null);
    const [message, setMessage] = useState("")
    const [name, setName] = useState("");
    

    const handleChange = (event) => {
        const newAccountID = event.target.value;
        changeAccountID(newAccountID);

        setLoading(true);
        setName("");
        setError("");
        apiFetch(`/app/orders/${newAccountID}`)
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Die Daten konnten nicht geladen werden.');
                }
                return res.json();
            })
            .then((data) => {
                setLoading(false);
                setName(data[0].Name || ""); // Set the name from the response
                setError("");
            })
            .catch((err) => {
                setLoading(false);
                setError(err.message);
                setName("");
            });
    };



    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControl sx={sx} size="small">
                <InputLabel id="account-select-small">Kunden-Nr.</InputLabel>
                <Select
                    labelId="account-select-small"
                    id="account-select-small"
                    value={accountID}
                    label="Kundennummer"
                    onChange={handleChange}
                >
                    {claims?.aid?.split(",").map((aid) => (
                        <MenuItem key={aid} value={aid}>{aid}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            {loading && <div style={{ marginLeft: '1rem' }}>Loading...</div>}
            {error && <div style={{ color: 'red', marginLeft: '1rem' }}>{error}</div>}
            {name && <div style={{ marginLeft: '1rem' }}>{name}</div>}
        </div>
    );
}


export function AccountSelectCheck(props) {
    let sx = props.sx;
    const { user, claims, accountID, changeAccountID } = useAuth();

    // Initialize selectedValues with all options from claims.aid if it exists
    const initialSelectedValues = claims?.aid ? claims.aid.split(",") : [];
    const [selectedValues, setSelectedValues] = useState(initialSelectedValues);

    const handleChange = (event) => {
        const newValue = event.target.value;
        console.log("handleChange: ", newValue);

        // Set the selected values based on user selection
        setSelectedValues(newValue);
        changeAccountID(newValue);
    };

    return (
        <FormControl variant="outlined" fullWidth sx={{ marginRight: '1rem', minWidth: '120px' }}>
            <InputLabel id="demo-multiple-checkbox-label">Kundennummer</InputLabel>
            <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedValues}
                onChange={handleChange}
                label="Kundennummer"
                renderValue={(selected) => `ausgewählt (${selected.length})`}
            >
                {claims?.aid?.split(",").map((kdnr) => (
                    <MenuItem key={kdnr} value={kdnr}>
                        <Checkbox checked={selectedValues.indexOf(kdnr) > -1} />
                        <ListItemText primary={kdnr} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}