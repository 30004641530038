import React, { useState, useEffect } from 'react';
import { useAuth, apiFetch } from "../hooks/useAuth";
// import {
//     DataGrid, GridRowsProp, GridColDef,
//     GridToolbarContainer, GridToolbarExport, GridActionsCellItem
// } from '@mui/x-data-grid';

import { DataGridPro, GridToolbar, GridToolbarContainer, GridToolbarExport, GridActionsCellItem, useGridApiRef } from '@mui/x-data-grid-pro';


import EmailIcon from '@mui/icons-material/Email';
import CircleIcon from '@mui/icons-material/Circle';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import {
    IconButton, FormControlLabel, Stack, Alert,
    LinearProgress, Box, Modal, Typography, Tooltip
} from "@mui/material/";
import dayjs from 'dayjs';
import { ModalDownload, ModalContact } from "./Modal";
import { deDE } from '@mui/x-data-grid-pro/locales'

export const CmdService = ({ index }) => {
    const handleEditClick = () => {
        // some action
    };

    return (
        <FormControlLabel
            control={
                <IconButton
                    color="secondary"
                    aria-label="add an alarm"
                    onClick={handleEditClick}
                >
                    <EmailIcon />
                </IconButton>
            }
        />
    );
};

export function OrderList() {
    const { accountID, token } = useAuth();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [message, setMessage] = useState("");
    const [data, setData] = useState([]);

    const [currentRow, setCurrentRow] = useState(null);
    const [isModalDownloadOpen, setIsModalDownloadOpen] = useState(false);
    const [isModalContactOpen, setIsModalContactOpen] = useState(false);


    const apiRef = useGridApiRef();
    const handleStatusClick = (status) => {

        apiRef.current.setFilterModel({

            items: [{ field: 'Bestellstatus', operator: 'contains', value: status }],
        });

    };

    const currencyFormatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
    });

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    printOptions={{
                        hideFooter: true,
                        hideToolbar: true,
                    }}
                />
            </GridToolbarContainer>
        );
    }



    useEffect(() => {
        setData("");

        if (!accountID) {
            setError("Es wurde keine AccountID ausgewaehlt");
            return;
        }

        if (!token) {
            setError("Es gibt keinen definierten User Token");
        }

        setLoading(true);

        apiFetch("/app/orders/" + accountID)
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Die Daten konnten nicht geladen werden. ');
                }
                return res.json();
            })
            .then((data) => {
                setLoading(false);
                console.log(data);
                setData(data);
                setError("");
                if (data.message)
                    setMessage(data.message);
            })
            .catch((err) => {
                setLoading(false);
                setError(err.message);
            });

    }, [accountID]);

    return (
        <div style={{ height: "670px", width: '100%' }}>
            <Box
                mt={2}
                mb={2}
                sx={{ width: '100%', height: 4 }}>
                {loading && <LinearProgress />}
            </Box>

            {!loading && error && <Alert severity="error">{error}</Alert>}
            {!loading && message && <Alert severity="info">{message}</Alert>}

            <ModalDownload row={currentRow} isModalOpen={isModalDownloadOpen} handleCloseModal={() => setIsModalDownloadOpen(false)} />
            <ModalContact row={currentRow} isModalOpen={isModalContactOpen} handleCloseModal={() => setIsModalContactOpen(false)} />

            <Stack direction="row" alignItems="center" gap={1}
                sx={{ mb: 2, fontSize: "small" }}
            >
                <IconButton onClick={() => handleStatusClick("geliefert")}>
                    <CircleIcon
                        sx={{ color: "#ffd700" }}
                    />

                    <Typography color="text.secondary">geliefert</Typography>
                </IconButton>


                <IconButton onClick={() => handleStatusClick("berechnet")}>
                    <CircleIcon
                        sx={{ color: "#228b22" }}
                    />
                    <Typography color="text.secondary">berechnet</Typography>
                </IconButton>


                <IconButton onClick={() => handleStatusClick("bestellt")}>
                    <CircleIcon
                        sx={{ color: "#ab2524" }}
                    />

                    <Typography color="text.secondary">bestellt</Typography>
                </IconButton>

                <IconButton onClick={() => handleStatusClick("storniert")}>
                    <CircleIcon
                        sx={{ color: "grey" }}
                    />
                    <Typography color="text.secondary">storniert</Typography>
                </IconButton>

            </Stack>

            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>

                    <DataGridPro
                        apiRef={apiRef}



                        pageSize={10}
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}


                        // getRowId={(row) => row.AuftragNr}
                        // componentsProps={{
                        //     toolbar: {
                        //         showQuickFilter: true,
                        //         quickFilterProps: { debounceMs: 500 },
                        //     },
                        // }}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                            },
                            filterPanel: {
                                onKeyDown: (event) => {
                                    console.log(event.key);
                                    if (event.key === 'Enter') {
                                        apiRef.current.hideFilterPanel()
                                    }
                                }
                            }
                        }}

                        initialState={{ pinnedColumns: { right: ['Reklamation'] } }}
                        rows={data}
                        columns={[
                            {
                                field: 'Bestellstatus',
                                headerName: "Status",
                                renderCell: (params) => {
                                    // console.log(params);
                                    let color = "";
                                    if (params.value === "geliefert") {
                                        color = "#ffd700";
                                    } else if (params.value === "berechnet") {
                                        color = "#228b22";
                                    } else if (params.value === "bestellt") {
                                        color = "#ab2524";
                                    } else if (params.value === "storniert") {
                                        color = "grey";
                                    }

                                    return (
                                        <CircleIcon
                                            sx={{ "color": color }}
                                        // color={params.value === "geliefert" ? "success" : "error"}
                                        />
                                    );
                                },
                                width: 80
                            },
                            {
                                field: 'AuftragNr',
                                headerName: "Auftragsnr.",
                                width: 80
                            },
                            {
                                field: 'KDNR',
                                headerName: "Kundennr.",
                                width: 80
                            },
                            {
                                field: 'Meldetext',
                                headerName: "Meldetext",
                                width: 80
                            },
                            {
                                field: 'ISBN',
                                width: 130
                            },
                            {
                                field: 'Titel',
                                width: 280,
                            },
                            {
                                field: "Anzahl",
                                width: 60,
                            },
                            {
                                field: 'Gesamtbetrag',
                                valueFormatter: (params) => {
                                    return currencyFormatter.format(params);
                                },
                                headerName: "Betrag",
                                type: "number",
                                width: 80,
                            },
                            {
                                field: 'Bestelldatum1',
                                headerName: "bestellt am",
                                type: "date",
                                valueFormatter: (params) => dayjs(params).format('DD.MM.YYYY'),

                            },
                            /*{
                                field: 'AuftragPosLfdnr',
                                headerName: "AuftragPosLfdnr"
                            },*/
                            {
                                field: 'Hinweis'
                            },
                            {
                                field: 'Kostenstelle'
                            },
                            {
                                field: 'ErscheintAm',
                                headerName: "erscheint am",
                                width: 150
                            },
                            {
                                field: 'geliefertAm',
                                headerName: "geliefert am"
                            },

                            {
                                field: 'actions',
                                type: 'actions',
                                headerName: 'Aktionen',
                                width: 100,
                                getActions: (params) => [
                                    <Tooltip title="Reklamation">
                                        <GridActionsCellItem
                                            onClick={() => {
                                                setCurrentRow(params.row);
                                                setIsModalContactOpen(true);
                                            }}
                                            icon={<EmailIcon />} label="Reklamation" />
                                    </Tooltip>,

                                    <Tooltip title="Belege herunterladen">
                                        <GridActionsCellItem
                                            onClick={() => {
                                                setCurrentRow(params.row);
                                                setIsModalDownloadOpen(true);
                                            }}
                                            icon={<CloudDownloadIcon />} label="Download" />
                                    </Tooltip>,
                                ],
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    )
}
